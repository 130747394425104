import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from 'react';

import "./App.css";
import heroVideo from "./assets/hero-video.mp4";

const Home = lazy(() => import('./pages/Home/Home'));
const About = lazy(() => import('./pages/About/About'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Header = lazy(() => import('./templates/Header/Header'));
const Portfolio = lazy(() => import('./pages/Portfolio/Portfolio'));



function App() {
	return (
		<section id="hero--section" className="hero--section">
			<video className="hero--video" loop autoPlay={true} muted preload="none">
				<source src={heroVideo} type="video/mp4"></source>
			</video>
			<div
				className="hero--section-divider"
				style={{
					position: "relative",
					width: "100%",
					height: "100%",
					display: "flex",
				}}
			>
				<Suspense>
					<Header />
				</Suspense>
				<div
					style={{
						position: "relative",
						flex: 1,
					}}
				>
					<Routes>
						<Route path="/" element={

							<Suspense>
								<Home />
							</Suspense>
						} />
						<Route path="/about" element={
							<Suspense>
								<About />
							</Suspense>
						} />
						<Route path="/contact" element={
							<Suspense>
								<Contact />
							</Suspense>
						}
						/>
						<Route path="/portfolio" element={
							<Suspense>
								<Portfolio />
							</Suspense>
						}
						/>
					</Routes>
				</div>
			</div>
		</section>
	);
}

export default App;
